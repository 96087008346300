import { graphql, navigate } from "gatsby"
import React, { useEffect } from "react"
import { Avatar, Box, Paragraph, ResponsiveContext } from "grommet"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
// import moment from "moment-timezone"
import Img from "gatsby-image"
import { UserManager } from "grommet-icons"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout"
import Wrapper from "../components/Container/Wrapper"
import { SectionWrapper } from "../pages/index"
import { H2, H3, H1, P } from "../components/Typography"

export default ({ data }) => {
  const { frontmatter, body, fields } = data.mdx
  const { t, i18n } = useTranslation()

  useEffect(() => {
    let route = fields.slug
    const clearRoute = route.replace("de/", "")
    const isGerman = fields.slug.includes("de")

    if (i18n.language === "de") {
      if (!isGerman) {
        route = `../${i18n.language + fields.slug}`
      }
    }
    if (i18n.language === "en") {
      route = clearRoute
    }
    navigate(route)
  }, [i18n.language, fields.slug])

  const readTime = (body.length - 300) / 130 / 10
  // let month = moment(frontmatter.date).format("MMMM")
  // let year = moment(frontmatter.date).format("YYYY")
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid

  const components = {
    h1: props => <H1 color="green" {...props} />,
    h2: props => <H2 color="dark-2" {...props} />,
    h3: props => (
      <H3
        size="small"
        color="dark-3"
        margin={{
          bottom: "xsmall",
          top: "small",
          horizontal: "0",
        }}
        style={{ maxWidth: "100%", whiteSpace: "pre-line" }}
        {...props}
      />
    ),
    h6: props => (
      <P
        color="dark"
        margin={{
          bottom: "medium",
          top: "xsmall",
          horizontal: "0",
        }}
        style={{ fontSize: "14px", maxWidth: "100%", textAlign: "center" }}
        {...props}
      />
    ),
    hr: props => (
      <hr
        style={{
          height: "1px",
          border: "none",
          color: "#DADADA",
          backgroundColor: "#DADADA",
          width: "100%",
        }}
      />
    ),
    p: props => (
      <P
        margin={{
          bottom: "medium",
          top: "xsmall",
          horizontal: "0",
        }}
        translate={false}
        style={{ maxWidth: "100%", lineHeight: 1.8 }}
        {...props}
      />
    ),
  }

  return (
    <>
      {/* {displayRequest && (
        <EmailRequest
          background="white"
          heading="Thank you for getting in touch!"
          message="We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon!"
          farewell="Have a great day!"
          onClickOutside={() => {
            setDisplayRequest(false)
          }}
        />
      )} */}
      <ResponsiveContext.Consumer>
        {size => (
          <Layout>
            <Box
              background="light-2"
              justify="center"
              align="center"
              margin={{ top: "75px" }}
            >
              <Box width="xlarge">
                <Wrapper top={size === "small" ? "medium" : "xlarge"}>
                  <Box direction={size === "small" ? "column-reverse" : "row"}>
                    <Box
                      justify="center"
                      basis="1/2"
                      direction="column"
                      gap="medium"
                    >
                      <Box
                        margin={
                          size === "small"
                            ? { top: "small", bottom: "medium" }
                            : { vertical: "none" }
                        }
                      >
                        <H2
                          margin={
                            size === "small"
                              ? {
                                  top: "medium",
                                  bottom: "xsmall",
                                  horizontal: "none",
                                }
                              : "none"
                          }
                          style={
                            size === "small"
                              ? { lineHeight: "30px", maxWidth: "100%" }
                              : { maxWidth: "85%" }
                          }
                        >
                          {frontmatter.title}
                        </H2>
                        <P
                          margin={
                            size === "small"
                              ? { top: "xsmall", bottom: "none" }
                              : { top: "small", bottom: "none" }
                          }
                          color="dark"
                          style={
                            size === "small"
                              ? { maxWidth: "100%" }
                              : { maxWidth: "85%" }
                          }
                        >
                          {frontmatter.subTitle}
                        </P>
                      </Box>
                      <Box direction="row" justify="between">
                        <Box direction="row">
                          <Box
                            pad={{ right: "small" }}
                            justify="center"
                            style={{ minWidth: "55px" }}
                          >
                            <Avatar size="medium" background="accent-1-dark">
                              <UserManager size="medium" />
                            </Avatar>
                          </Box>
                          <Box direction="column">
                            <Box>
                              <P margin="none" color="dark" bold>
                                {frontmatter.autor}
                              </P>

                              <Paragraph
                                margin={{ vertical: "none" }}
                                color="dark-3"
                                size="small"
                              >
                                {t("blog:useCase")} -
                                {" " +
                                  Math.floor(readTime) +
                                  " " +
                                  t("blog:readDuration")}
                              </Paragraph>
                            </Box>
                          </Box>
                        </Box>
                        {/* {size === "small" ? (
                        <Box justify="end" align="end">
                          <Paragraph color="dark-3" margin="none" size="small">
                            {t(`month:${month}`) + " " + year}
                          </Paragraph>
                          <Paragraph
                            color="dark-5"
                            size="small"
                            margin={{ top: "4px", bottom: "0" }}
                          >
                            {Math.floor(readTime) +
                              " " +
                              t("blog:readDuration")}
                          </Paragraph>
                        </Box>
                      ) : null} */}
                      </Box>
                    </Box>
                    <Box basis="1/2" justify="center">
                      <Img
                        fluid={featuredImgFluid}
                        style={{ borderRadius: 4 }}
                      />
                    </Box>
                  </Box>
                </Wrapper>
              </Box>
            </Box>

            <SectionWrapper>
              <Wrapper align="center" direction="column">
                <Box
                  width="xlarge"
                  pad={
                    size === "small"
                      ? { horizontal: "none", vertical: "none" }
                      : { horizontal: "xlarge", vertical: "none" }
                  }
                >
                  <Box
                    margin={{ bottom: "large" }}

                    // style={{ borderLeft: "5px solid #5648CB" }}
                  >
                    <H2 color="dark-2">stories:short</H2>
                    <P
                      highLines
                      margin={{
                        bottom: "0",
                        top: "xsmall",
                        horizontal: "0",
                      }}
                    >
                      {frontmatter.short}
                    </P>
                  </Box>

                  <MDXProvider components={components}>
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXProvider>
                </Box>

                {/* <BottomScrollListener
              onBottom={handleOnDocumentBottom}
              offset={300}
            /> */}
              </Wrapper>
            </SectionWrapper>
          </Layout>
        )}
      </ResponsiveContext.Consumer>
    </>
  )
}

export const query = graphql`
  query StoriesBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        short
        subTitle
        autor
        date
        category
        title
        tags
      }
      body
    }
  }
`
